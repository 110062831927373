<template>
  <div class="booking-module-mobile__tabs">
    <ul id="form-tabs" class="form-tabs">
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showParkingFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="parkingTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingParkingWidget', {
              stickyBookingParkingWidgetSettings: pinnedBookingSettings,
              selectedLanguage: pinnedBookingSelectedLanguage,
              widgetBookingPageInfo: pinnedBookingPageInfo,
            })
          "
          ><SvgIcon name="parking" class="icon" />Parking</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showSecurityFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="securityTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarSecurityForm', {
              securityPinnedBookingBarSettings: pinnedBookingPublicSiteSettings,
            })
          "
          ><SvgIcon name="insurance" class="icon" />Security</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showLoungesFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="loungeTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarLoungeForm', {
              loungePinnedBookingBarSettings: pinnedBookingPublicSiteSettings,
            })
          "
          ><SvgIcon name="lounge" class="icon" />Lounge</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showHolidaysFormInBookingBar
        "
      >
        <a
          href="javascript:void(0)"
          id="holidaysTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarHolidaysForm', {})
          "
        >
          <SvgIcon name="plane" class="icon" />Book a Holiday</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields
            .showCarRentalFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="carrentalTab"
          @click.prevent="$emit('modal-open', 'StickyBookingBarCarForm', {})"
          ><SvgIcon name="carrental" class="icon" />Car Rental</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showTaxisFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="taxisTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarBookTaxiForm', {})
          "
          ><SvgIcon name="car" class="icon" />Taxis</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showHotelsFormInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="hotelsTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarHotelsForm', {
              selectedLanguage: pinnedBookingSelectedLanguage,
            })
          "
          ><SvgIcon name="hotels" class="icon" />Hotels</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showCurrencyFormInBookingBar
        "
      >
        <a
          href="javascript:void(0)"
          id="currencyTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarCurrencyForm', {
              currencyPinnedBookingBarSettings: pinnedBookingPublicSiteSettings,
            })
          "
          ><SvgIcon name="euros" class="icon" />Currency</a
        >
      </li>
      <li
        v-if="
          pinnedBookingPublicSiteSettings.fields.showHotelsIframeInBookingBanner
        "
      >
        <a
          href="javascript:void(0)"
          id="hotelsIFrameTab"
          @click.prevent="
            $emit('modal-open', 'StickyBookingBarHotelsIFrame', {
              hotelIFramePinnedBookingBarSettings:
                pinnedBookingPublicSiteSettings,
            })
          "
          ><SvgIcon name="hotels" class="icon" />Hotels</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import SvgIcon from "@/components/Icon";

export default {
  name: "StickyFormsBookingBarMobile",
  props: {
    pinnedBookingSettings: Object,
    pinnedBookingSelectedLanguage: String,
    pinnedBookingPublicSiteSettings: Object,
    pinnedBookingPageInfo: Object,
  },
  inject: ["labels"],
  emits: ["modal-open"],
  components: {
    SvgIcon,
  },
  mounted() {},
  methods() {},
};
</script>

<style lang="scss">
.modal-content {
  .grid {
    & > div {
      float: left !important;
      clear: none !important;
      margin-right: 0px !important;
      min-width: 70px;
    }

    .grid__half {
      & > div {
        float: left !important;
        clear: none !important;
        margin-right: 10px !important;
      }
    }

    .grid__sixth {
      width: 50%;
    }

    .grid__third {
      width: 50%;
    }

    .grid_adult,
    .grid_child {
      width: 25%;
    }

    .grid__rest {
      display: flex;
      margin-top: 4rem;
      width: 100%;
      justify-content: center;
    }

    .grid_full {
      width: 100%;
    }
  }

  .btn--secondary {
    margin-left: 1rem;
    display: none;

    @include breakpoint($tablet-sml) {
      display: block;
    }
  }

  .hiddenFromView {
    display: inline-block;
    text-indent: -9999px;
  }

  header {
    display: none;
  }
  fieldset {
    display: table;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;

    &.buttons {
      margin-top: 20px;
    }

    label {
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
    }

    .block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      label {
        grid-area: 1 / 1 / 2 / 3;
      }
      .datepicker {
        grid-area: 2 / 1 / 3 / 2;
      }

      .select-box-time {
        grid-area: 2 / 2 / 3 / 3;
      }

      &.button {
        padding-right: 0;
      }

      &.terminal {
        & > div {
          grid-area: 2 / 1 / 2 / 3;
        }
      }

      &.button {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}

.stickybookingbarsecurityform,
.stickybookingbarcurrencyform {
  .modal-content {
    .grid {
      .grid__sixth:nth-child(3) {
        width: 100%;
      }
    }
  }
}

.stickybookingbarbooktaxiform,
.stickybookingbarcarform {
  .modal-content {
    .grid {
      .grid__third,
      .grid__half {
        width: 100%;
      }

      .grid__half {
        margin-top: 20px;
      }

      .date-time {
        display: flex;
      }

      .col-time-mins,
      .col-time-hours {
        width: 50%;
      }
    }
  }
}

.stickybookingbarcarform {
  .grid__half {
    display: flex;
    justify-content: center;
  }
}

.stickybookingbarbooktaxiform {
  label[for="pickUpMinute"],
  label[for="dropOffMinute"] {
    display: none;
  }

  label[for="pickUpHour"],
  label[for="dropOffHour"] {
    text-align: left;
    padding-left: 45px;
  }

  .grid {
    .grid__half {
      @include breakpoint($tablet-sml) {
        display: flex;
        justify-content: center;
      }

      .btn-booktaxi {
        margin-top: 0;
      }

      .grid__third {
        margin-right: 0 !important;
        width: 100%;

        @include breakpoint($tablet-sml) {
          width: 33.33%;
        }
      }

      &:nth-child(2) {
        display: flex;
        justify-content: center;
      }

      .grid__quarter {
        width: 30%;
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }
  }
}

.stickybookingparkingwidget {
  .grid__sixth:nth-child(5) {
    width: 100%;
  }
}

.booking-module-mobile__tabs {
  width: 100%;
  height: 100%;
  background: $skin-colour-primary;
  display: none;

  @include breakpoint($tablet) {
    height: 50px;
  }

  @include breakpoint($desktop-sml-and-below) {
    display: block;
  }

  ul {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    @include breakpoint($tablet) {
      flex-direction: row;
    }

    li {
      flex-grow: 1;
      border-bottom: 0.5px solid #948d80;
      height: 70px;

      @include breakpoint($tablet) {
        height: auto;
        border-right: 0.5px solid #948d80;

        &:last-child {
          border-right: none;
        }
      }

      a {
        color: #fff;
        font-size: 1.9rem;
        text-decoration: none;
        white-space: nowrap;
        cursor: pointer;
        justify-content: flex-start;
        padding-left: 5rem;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;

        @include breakpoint($tablet) {
          justify-content: center;
          padding-left: 0rem;
          font-size: 1.7rem;
        }

        svg {
          color: #fff;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
