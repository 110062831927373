<template>
  <section class="panel-padding">
    <div class="inner">
      <div class="blog-article-tags-container">
        <h2 class="heading">
          {{ componentData.fields.largeBlogArticlesListHeading }}
        </h2>

        <button class="btn btn--keyline blog-article-filter" @click="toggleFilterBlock()">
          Filter your results <span>{{ articleFilterSign }}</span>
        </button>

        <div v-if="!componentData.fields.hideTagFIlters" class="blog-article-tags" :class="{
              'blog-article-tags--active': activeFilterBlock,
            }">
          <button v-for="tag in blogTags" :key="tag.id" class="btn btn--secondary btn--small btn--tag"
            :class="{ 'active-tag': tag.blogTagName === selectedTag }" @click="toggleFilter(tag.blogTagName)">
            {{ tag.blogTagName }}
          </button>
        </div>
      </div>

      <div v-if="articles != null" :class="`blog-article-block-container ${getBlogArticleBlockClass(
            index
          )}`">
        <div v-for="(article, index) in paginatedBlogArticles[currentPage]" :key="index" class="blog-article-block">
          <a :href="article.articleLink" class="blog-article-link">
            <div class="blog-article-content">
              <h3 class="blog-article-content__subtitle">
                {{ article.newsDate.split(' ')[0] }}
              </h3>
              <h4 class="blog-article-content__title">
                {{ article.headerTitle }}
              </h4>
            </div>
            <img class="blog-article-image" :src="article.headerImage" :alt="article.headerTitle" />
          </a>
        </div>
      </div>
    </div>

    <div v-if="articles != null" class="live-flights__pagination">
      <div class="pagination pagination--dynamic">
        <ul>
          <li class="pagination__prev">
            <a href="" class="btn btn--secondary" @click.prevent="prevPage" :class="{
                        disabled: currentPage === 0,
                      }">Prev</a>
          </li>
          <li v-for="page in pages" :key="page">
            <a href="" :class="{ pagination__current: currentPage === page }" @click.prevent="goToPage(page)">
              {{ page + 1 }}
            </a>
          </li>
          <li class="pagination__next">
            <a href="" class="btn btn--primary" @click.prevent="nextPage" :class="{
                        disabled: currentPage === paginatedBlogArticles.length - 1,
                      }">Next</a>
          </li>
        </ul>
      </div>
    </div>
    <h2 v-if="articles == null">Sorry, there are currently no blog articles available</h2>

  </section>
</template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "LargeArticlesList",
    props: ["selectedLanguage", "componentData"],
    data() {
      return {
        articles: [],
        webpage: "",
        blogTags: [],
        activeFilterBlock: false,
        articleFilterSign: "+",
        selectedTag: this.componentData.fields.startingFilterTag?.system.name != null ? this.componentData.fields.startingFilterTag?.system.name : null,
        pageSize: this.componentData.fields.articleListSize || 5,
        currentPage: 0,
        contentNodePickerUrl: this.componentData.fields.contentNodePicker?.system.urlSegment,
      };
    },
    mounted() {
    this.loadArticles();
  },
    watch: {
      selectedLanguage: "reloadPage",
    },
    methods: {
      reloadPage() {
        window.location.reload();
      },
      getBlogArticleBlockClass(index) {
        const cycleLength = 5;
        const cycle = index % cycleLength;
        return "blog-article-block-container-type" + (cycle + 1);
      },
      goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.paginatedBlogArticles.length - 1) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
      loadArticles() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

        const url = this.contentNodePickerUrl != null ? "/" + this.contentNodePickerUrl + "/"  : this.webpage;
 
      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/BlogPostsApi/GetAllArticles?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;
        
      axios
        .get(apiUrl)
        .then((response) => {
          this.articles = response.data.articles;
          this.articles = Object.keys(this.articles).length === 0 || this.articles.length < 1 ? null : this.articles.sort((a, b) => {
            const dateA = this.parseDateTime(a.newsDate);
            const dateB = this.parseDateTime(b.newsDate);
            return dateB - dateA;
          });
          this.blogTags = response.data.blogTags;  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      filteredBlogArticles() {
        const cycleLength = 5;
        let _articleBlocks = [];
        let _dest = this.articles;
        if (this.selectedTag) {
          _dest = this.articles.filter((article) => {
            return article.categories.includes(this.selectedTag);
          });
        } else {
          _dest = this.articles;
        }       
  
        //Create blocks of 5 from the resulting array for display purposes.
        for (let i = 0; i < _dest.length; i += cycleLength) {
          _articleBlocks.push(_dest.slice(i, i + cycleLength));
        }
  
        return _articleBlocks;
      },
      toggleFilterBlock() {
        this.activeFilterBlock = !this.activeFilterBlock;
        if (this.activeFilterBlock) {
          this.articleFilterSign = "+";
        } else {
          this.articleFilterSign = "-";
        }
      },
      toggleFilter(tag = null) {
        if (this.selectedTag === tag) {
          this.selectedTag = null;
        } else {
          this.selectedTag = tag;
        }
      },
      parseDateTime(dateTimeStr) {
      const [dateStr, timeStr] = dateTimeStr.split(" ");
      const [day, month, year] = dateStr.split("/").map(Number);
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-based in Date constructor
    },
    },
    computed: {
      paginatedBlogArticles: function () {
        let data = this.filteredBlogArticles().flat();
      return data.reduce((pages, item, index) => {
        const page = Math.floor(index / this.pageSize);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
        return pages;
      }, []);
    },
    pages: function () {
      const pageNumbers = [];
      for (let i = 0; i <= this.paginatedBlogArticles.length - 1; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .blog-article-tags-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: 30px auto;
  
    .heading {
      text-align: center;
    }
  }
  .btn--tag {
    background: white !important;
    color: $btn-primary !important;
  
    &:hover {
      background: $btn-primary !important;
      color: #fff !important;
    }
  
    &:focus {
      background: $btn-primary !important;
      color: #fff !important;
    }
    &.active-tag {
      background: $btn-primary !important;
    }
  }
  .blog-article-filter {
    width: 80%;
  
    @include breakpoint($tablet) {
      display: none;
    }
  }
  .blog-article-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    display: none;
  
    &--active {
      display: flex;
    }
  
    @include breakpoint($tablet) {
      display: flex;
    }
  }
  .blog-article-block-container {
    display: grid;
    grid-gap: 20px;
    grid-template-rows: repeat(4, 200px);
    margin-bottom: 20px;
  
    @include breakpoint($tablet) {
      grid-template-rows: repeat(2, minmax(100px, 170px));
    }
    @include breakpoint($desktop) {
      grid-template-rows: repeat(2, minmax(100px, 270px));
    }
  }
  .blog-article-block-container-type1 {
    grid-template-columns: 1fr 1fr;
  
    @include breakpoint($tablet) {
      grid-template-columns: 50% repeat(2, minmax(100px, 270px));
    }
    .blog-article-block:first-child {
      grid-area: 1 / 1 / 3 / 3;
  
      @include breakpoint($tablet) {
        grid-area: 1 / 1 / 3 / 2;
      }
    }
  }
  .blog-article-block-container-type2 {
    grid-template-columns: 1fr 1fr;
  
    @include breakpoint($tablet) {
      grid-template-columns: minmax(100px, 270px) 50% minmax(100px, 270px);
    }
    .blog-article-block:nth-child(3) {
      grid-area: 2 / 1 / 4 / 3;
  
      @include breakpoint($tablet) {
        grid-area: 1 / 2 / 3 / 3;
      }
    }
  }
  .blog-article-block-container-type3 {
    grid-template-columns: 1fr 1fr;
  
    @include breakpoint($tablet) {
      grid-template-columns: repeat(2, minmax(100px, 270px)) 50%;
    }
    .blog-article-block:last-child {
      grid-area: 3 / 1 / 5 / 3;
  
      @include breakpoint($tablet) {
        grid-area: 1 / 3 / 3 / 4;
      }
    }
  }
  .blog-article-block {
    border-radius: 12px;
    overflow: hidden;
  }
  .blog-article-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .blog-article-content__subtitle {
    font-size: 1.4rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
    line-height: 1;
    margin-bottom: 0;
  }
  .blog-article-content__title {
    font-size: 2rem;
    @include breakpoint($desktop-sml) {
      font-size: 3.2rem;
    }
    color: #fff;
    text-align: center;
    margin-bottom: 0;
  }
  .blog-article-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .blog-article-link {
    text-decoration: none;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  
    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 12px;
      z-index: 1;
    }
  
    &:hover:before,
    &:focus:before {
      background: rgba(0, 0, 0, 0.5);
      -webkit-transition: background 0.25s linear;
      -o-transition: background 0.25s linear;
      transition: background 0.25s linear;
    }
  }

  .live-flights__pagination {
  text-align: center;
  display: none;
  margin-top: 3rem;

  @include breakpoint($tablet-sml) {
    display: block;
  }

  li {
    display: inline-block;

    a:not(.btn) {
      display: block;
      font-size: 1.8rem;
      color: $live-flights-btns-color;
      margin: 0 0.5rem;
      padding: 1rem;
      cursor: pointer;

      &.pagination__current,
      &:hover,
      &:active {
        color: #213368;
        text-decoration: none;
      }
    }

    &.unclickable a {
      cursor: default;
      color: #00a8e1;

      &.pagination__current,
      &:hover,
      &:active {
        cursor: default;
        color: #00a8e1;
      }
    }
  }

  &--dynamic {
    display: block;

    @media (max-width: 899px) {
      li {
        display: none;

        &.pagination__prev,
        &.pagination__next {
          display: inline-block;

          &.disabled {
            display: none;
          }
        }

        a {
          margin: 0;
        }
      }
    }

    .pagination__prev {
      float: left;
    }

    .pagination__next {
      float: right;
    }

    .disabled {
      visibility: hidden;
    }
  }
}
  </style>
  