<template>
  <swiper
    :slides-per-view="1"
    :space-between="0"
    :loop="heroCollection.length > 1"
    :autoplay="{
      delay: duration,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }"
    :allow-touch-move="false"
    :navigation="heroCollection.length > 1"
    :pagination="{
      clickable: true,
    }"
  >
    <swiper-slide v-for="(hero, index) in heroCollection" :key="index">
      <component
        :is="hero.system.contentType"
        :heroData="hero"
        :herosSiteSettings="heroSiteSettings"
      ></component>
    </swiper-slide>
  </swiper>
</template>
<script>
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import HeroPlain from "@/components/HeroSlides/HeroPlain.vue";
import HeroParkingFeatures from "@/components/HeroSlides/HeroParkingFeatures.vue";
import HeroCar from "@/components/HeroSlides/HeroCar.vue";
import HeroCruise from "@/components/HeroSlides/HeroCruise.vue";
import HeroSecurity from "@/components/HeroSlides/HeroSecurity.vue";
import HeroLounge from "@/components/HeroSlides/HeroLounge.vue";
import HeroCurrency from "@/components/HeroSlides/HeroCurrency.vue";
import HeroLiveFlights from "@/components/HeroSlides/HeroLiveFlights.vue";

// TODO - decide if all of these are needed
import "@/assets/scss/plugins/swiper/swiper.scss";
import "@/assets/scss/plugins/swiper/components/navigation/navigation.scss";
import "@/assets/scss/plugins/swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";

SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  name: "HeroSlider",
  components: {
    Swiper,
    SwiperSlide,
    HeroPlain,
    HeroParkingFeatures,
    HeroCar,
    HeroCruise,
    HeroSecurity,
    HeroLounge,
    HeroCurrency,
    HeroLiveFlights,
  },
  props: ["heroCollection", "duration", "heroSiteSettings"],
};
</script>

<style lang="scss">
:root {
  --swiper-navigation-size: 30px;
  --swiper-navigation-color: white;
}

.hero {
  &__heading {
    @extend h1;
    color: #fff !important;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      max-width: none;
    }
  }

  &__gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-image: linear-gradient(
      -107deg,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0.13) 93%
    );
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 72rem;
    margin: 0 auto;
    text-align: left;

    @include breakpoint($desktop) {
      max-width: 82rem;
    }

    .breadcrumb li a span {
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }

  p {
    font-size: 1.9rem;
    margin-top: -1.5rem;
    font-family: $font-headings;
    margin-bottom: 3rem;
    color: $white;

    @include breakpoint($tablet) {
      max-width: 75%;
    }

    @include breakpoint($desktop-sml) {
      font-size: 3rem;
      line-height: 1.2;
      max-width: none;
    }
  }

  &-slider {
    overflow-y: hidden;

    &__slide {
      position: relative;
      color: $white;
      height: 65rem;

      background: {
        repeat: no-repeat;
        position: 50% 50%;
        size: cover;
      }

      .inner {
        top: 40%;
        transform: translateY(-40%);
        position: relative;
        z-index: 2;
        padding: 0 2rem; //  min-height: 25rem;
        margin: 0;

        @include breakpoint($tablet) {
          padding: 0 4rem; //  min-height: 25rem;
        }

        @include breakpoint($desktop) {
          padding: 0 0;
        }
      }

      .center {
        margin: 0 auto;

        .hero__content {
          text-align: center;
        }
      }
    }
  }
}

.select2-selection__placeholder {
  color: $skin-colour-primary !important;
}

/* Hero Forms */
.hero-form {
  &__col {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    .calendar {
      border-right: 1px solid #e0e4f0;
    }

    input,
    .select2-selection--single,
    .select2-selection__rendered {
      width: 18.5rem;
      border: none;
      height: 50px;
      line-height: 50px;
      font-size: 1.8rem;
    }
  }

  &--mobile {
    @include breakpoint($tablet) {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include breakpoint($tablet) {
      display: block;
    }
  }

  &--booking {
    label {
      color: #fff;
    }

    .grid {
      margin-bottom: 1rem;
    }

    .grid--inner .grid__half {
      clear: none !important;
      margin: 0 !important;
      width: 50%;
    }

    .grid__half {
      float: left !important;

      input,
      .select2-selection--single,
      .select2-selection__rendered {
        width: 100%;
      }

      .calendar {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }

      .time {
        padding-left: 0.1rem;
      }

      .grid {
        margin-bottom: 0;

        .grid__half {
          margin-bottom: 1rem;
        }
      }
    }

    .btn,
    a {
      margin-top: 1rem;
    }
  }
}

.selectbox-time + .select2-container .select2-selection__arrow {
  display: none;
}

.selectbox-time + .select2-container .select2-selection__rendered {
  font-weight: 400;

  @media (max-width: 899px) {
    border: none;
    line-height: 48px;
  }
}

.booking-module__error {
  padding: 10px 20px;
  display: block;
  margin-top: 2rem;
  background: #f1cece;
  color: #ff1101;
  border: 1px solid #ff1101;
  font-size: 14px;
  text-align: center;

  @include breakpoint($tablet-sml) {
    display: inline-block;
    max-width: none;
  }
}
/* SWIPER */
.swiper-button-prev,
.swiper-button-next {
  display: none;

  @include breakpoint($desktop-sml) {
    display: block;
  }
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: 2rem;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: $slider-dots-bg;
    opacity: 1;

    &-active {
      background: $slider-dots-bg-active;
    }
  }

  // @include breakpoint($desktop-sml) {
  //   left: auto;
  //   right: 1.5rem;
  //   top: 8.5rem;
  //   bottom: auto;
  //   width: auto;
  // }
}

.btn--secondary {
  background: $white !important;
}
</style>
