<template>
  <section class="inner panel-padding">
    <h2 v-if="articles == null" >Sorry, there are currently no blog articles available</h2>
    <h2 v-if="componentData.fields.articleListHeader && articles != null" class="heading">
      {{ componentData.fields.articleListHeader }}
    </h2>
    <div v-if="articles != null" class="grid social-panel">
      <div class="grid__third">
        <section class="media-centre">
          <h3>
            <i
              v-if="componentData.fields.articleListSubHeader"
              class="icon icon-media"
              aria-hidden="true"
            ></i
            >{{ componentData.fields.articleListSubHeader }}
          </h3>
          <ul class="media-centre__feed">
            <li v-for="(article, index) in restOfArticles" :key="index">
              <a :href="article.articleLink" title="View more">
                <span>{{ article.newsDate.split(" ")[0] }}</span>
                <h4>
                  {{ article.headerTitle }}
                </h4>
                <i class="icon icon-arrow" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
          <a
            v-if="componentData.fields.viewAllAnnouncementsLink"
            :href="componentData.fields.viewAllAnnouncementsLink.url"
            class="btn btn--link"
            title="View all announcements"
            ><i class="icon icon-arrow" aria-hidden="true"></i
            >{{ componentData.fields.viewAllAnnouncementsLink.name }}</a
          >
        </section>
      </div>
      <div class="grid__third">
        <section class="blog-widget">
          <h3>
            <i class="icon icon-blog" aria-hidden="true"></i
            >{{ firstArticle.headerTitle }}
          </h3>
          <img :src="firstArticle.headerImage" class="blog-widget__img" alt="Article Image">
          <p v-html="firstArticle.blurb"></p>
        </section>
        <p></p>
        <a
          :href="firstArticle.articleLink"
          class="btn btn--link"
          title="Read more"
          ><i class="icon icon-arrow" aria-hidden="true"></i>Read more</a
        >
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "SmallArticlesList",
  props: ["selectedLanguage", "componentData"],
  data() {
    return {
      articles: [],
      orderedArticles: [],
      firstArticle: {},
      restOfArticles: [],
      tags: [],
      contentNodePickerUrl: this.componentData.fields.contentNodePicker?.system.urlSegment,
    };
  },
  mounted() {
    this.loadArticles();
  },
  watch: {
    selectedLanguage: "loadArticles",
  },
  methods: {
    loadArticles() {
      this.webpage = this.$route.params.slug
        ? "/" + this.$route.params.slug.replace(/\/?$/, "/")
        : "/";

        const url = this.contentNodePickerUrl != null ? "/" + this.contentNodePickerUrl + "/"  : this.webpage;

      const apiUrl =
        process.env.VUE_APP_REMOTEBASEURL +
        `/api/BlogPostsApi/GetAllArticles?url=${url}&iataCode=${process.env.VUE_APP_IATACODE}&cultureCode=${this.selectedLanguage}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.articles = response.data.articles;
          this.articles = Object.keys(this.articles).length === 0 || this.articles.length < 1 ? null : this.articles;
          this.orderedArticles = response.data.articles.sort((a, b) => {
            const dateA = this.parseDateTime(a.newsDate);
            const dateB = this.parseDateTime(b.newsDate);
            return dateB - dateA;
          });
          this.firstArticle = this.orderedArticles[0];
          this.restOfArticles =
            this.orderedArticles.length >= 6
              ? this.orderedArticles.slice(1, 6)
              : this.orderedArticles.slice(1);
          this.tags = response.data.blogTags;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseDateTime(dateTimeStr) {
      const [dateStr, timeStr] = dateTimeStr.split(" ");
      const [day, month, year] = dateStr.split("/").map(Number);
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);
      return new Date(year, month - 1, day, hours, minutes, seconds); // month is 0-based in Date constructor
    },
  },
};
</script>

<style lang="scss" scoped>
.social-panel {
  div {
    position: relative;
  }

  @include breakpoint($tablet) {
    div {
      padding-bottom: 3rem;
    }
    .btn--link {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  path,
  rect {
    fill: cyan;
  }

  .icon-arrow {
    g {
      stroke: cyan;
    }
  }
}

.media-centre {
  margin-bottom: 5rem;

  @include breakpoint($tablet) {
    margin-bottom: 0;
  }

  @include breakpoint($desktop) {
    padding-right: 2rem;
  }

  &__feed {
    display: block;
    margin-bottom: 1.5rem;

    li:first-of-type a {
      border-top: 1px solid darkblue;
    }

    a {
      position: relative;
      display: block;
      padding: 1.8rem 2.5rem 1.8rem 1rem;
      border-bottom: 1px solid darkblue;

      &:hover,
      &:focus {
        background: #f7f6f0;
      }

      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }
    }

    h4,
    span {
      @extend .small-text;
    }

    h4 {
      font-weight: $reg;
      margin: 0;
    }

    span {
      color: #9099b3;
    }
  }
}

.grid__third {
  lost-column: 1/1;
  margin-bottom: 2rem;

  @include breakpoint($tablet) {
    lost-column: 1/1;
  }

  @include breakpoint($desktop-sml) {
    margin-bottom: 2rem;
    lost-column: 1/3;
  }
}
</style>
