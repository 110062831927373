<template>
  <section class="live-flight-list panel-padding">
    <h2 class="heading">{{ componentData.fields.title }}</h2>
    <div v-if="flights.departures || flights.arrivals">
      <div class="inner centre-text cf">
        <p class="right-text">
          {{ this.labels.value.labelLastUpdated }}:
          <strong>{{ formatDateTime(flights.lastUpdfated, "time") }}</strong>
        </p>

        <!-- Flight Type Picker -->
        <ul class="live-flights__btns">
          <li>
            <a
              class="btn"
              :class="{
                'js-active': guiState.activeFlightType == 'departures',
              }"
              @click="switchTab('departures')"
              >{{ this.labels.value.labelDepartures }}</a
            >
          </li>
          <li>
            <a
              class="btn"
              :class="{ 'js-active': guiState.activeFlightType == 'arrivals' }"
              @click="switchTab('arrivals')"
              >{{ this.labels.value.labelArrivals }}</a
            >
          </li>
        </ul>

        <!-- Search Form -->
        <form class="live-flights-search" v-on:submit.prevent>
          <input
            type="text"
            placeholder="Search by number, city or airline"
            class="live-flights-search__field"
            v-model="searchTerm"
          />
          <div class="live-flights-search__wrapper">
            <SvgIcon name="search" class="icon icon-search" />
          </div>
        </form>

        <!-- Flight Table -->
        <div>
          <section class="live-flights__tab js-active cf">
            <div class="live-flights__header">
              <span class="time">Time</span>
              <span class="airline">Airline</span>
              <span class="number">Flight</span>
              <span
                class="destination"
                v-if="guiState.activeFlightType == 'arrivals'"
                >From</span
              >
              <span
                class="destination"
                v-if="guiState.activeFlightType == 'departures'"
                >To</span
              >
              <span class="status">Status</span>
              <span class="options" v-if="settings.enableFlightUpdates"
                >Flight Updates</span
              >
            </div>
            <p class="no-results-text" v-if="this.filteredFlights.length === 0">
              {{ noResultsText }}
            </p>
            <div
              class="flight cf"
              v-for="(flight, index) in this.paginatedFlights[this.currentPage]"
              :key="index"
            >
              <!--Mobile-->
              <!--timeline link-->
              <a
                v-if="timelinePage != null"
                :href="
                  getTimePanelLink(
                    timelinePage.url,
                    flight.flightNumber,
                    flight.scheduledDateTime,
                    flight.arriving ? 0 : 1
                  )
                "
                :target="_blank"
              >
                <div
                  :class="`flight-mobile${
                    this.expandMobile === index ? ' expanded' : ''
                  }`"
                >
                  <div class="flight__row">
                    <div class="flight__destination">
                      {{ flight.location }}
                    </div>
                    <div class="flight__time">
                      {{ formatDateTime(flight.scheduledDateTime, "time") }}
                    </div>
                    <div class="flight__number">
                      <span v-if="!flight.codeShares">
                        {{ flight.flightNumber }}
                      </span>
                      <div class="codeshare" v-if="flight.codeShares">
                        <div class="codeshare__flight-no dynamic">
                          <swiper
                            :direction="'vertical'"
                            :autoplay="{
                              delay: 2000,
                            }"
                          >
                            <swiper-slide>{{
                              flight.flightNumber
                            }}</swiper-slide>
                            <swiper-slide>
                              <div>
                                {{ flight.codeShares[0].airline + " " }}
                                {{ flight.codeShares[0].flightNumber }}
                              </div></swiper-slide
                            >
                          </swiper>
                        </div>
                        <div class="codeshare__number">
                          <span>+{{ flight.codeShares.length }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flight__status">
                      {{ flight.statusMessage.mainMessage }}
                    </div>
                    <div
                      class="flight__options"
                      v-if="settings.enableFlightUpdates"
                    >
                      <a
                        class="icon-wrapper"
                        href="#"
                        @click.prevent="expandMobileUpdates(index)"
                      >
                        <SvgIcon name="bell" class="icon icon-bell"
                      /></a>
                    </div>
                  </div>
                  <div
                    class="flight__update-options"
                    v-if="settings.enableFlightUpdates"
                  >
                    <label>Get flight updates</label>
                    <a
                      class="icon-wrapper"
                      href="#"
                      @click.prevent="
                        $emit('modal-open', 'WatchFlightModal', {
                          modalHeaderText: modalHeaderText(flight),
                          modalLabelText: settings.emailModalTitle,
                          emailModalOptin: settings.emailModalOptin,
                          emailModalSuccess: settings.emailModalSuccess,
                          flightNumber: flight.flightNumber,
                          flightDateTime: flight.scheduledDateTime,
                        })
                      "
                      ><SvgIcon name="email" class="icon"
                    /></a>
                    <a
                      class="icon-wrapper"
                      href="#"
                      @click.prevent="expandMobileUpdates(null)"
                    >
                      <SvgIcon name="close" class="icon icon-close-flight"
                    /></a>
                  </div>
                </div>
              </a>
              <!--No timeline link-->
              <div
                v-if="timelinePage == null"
                :class="`flight-mobile${
                  this.expandMobile === index ? ' expanded' : ''
                }`"
              >
                <div class="flight__row">
                  <div class="flight__destination">
                    {{ flight.location }}
                  </div>
                  <div class="flight__time">
                    {{
                      flight.dateAndTime !== null
                        ? flight.dateAndTime
                        : formatDateTime(flight.scheduledDateTime, "time")
                    }}
                  </div>
                  <div class="flight__number">
                    <span v-if="!flight.codeShares">
                      {{ flight.flightNumber }}
                    </span>
                    <div class="codeshare" v-if="flight.codeShares">
                      <div class="codeshare__flight-no dynamic">
                        <swiper
                          :direction="'vertical'"
                          :autoplay="{
                            delay: 2000,
                          }"
                        >
                          <swiper-slide>{{ flight.flightNumber }}</swiper-slide>
                          <swiper-slide>
                            <div>
                              {{ flight.codeShares[0].airline + " " }}
                              {{ flight.codeShares[0].flightNumber }}
                            </div></swiper-slide
                          >
                        </swiper>
                      </div>
                      <div class="codeshare__number">
                        <span>+{{ flight.codeShares.length }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flight__status">
                    {{ flight.statusMessage.mainMessage }}
                  </div>
                  <div
                    class="flight__options"
                    v-if="settings.enableFlightUpdates"
                  >
                    <a
                      class="icon-wrapper"
                      href="#"
                      @click.prevent="expandMobileUpdates(index)"
                    >
                      <SvgIcon name="bell" class="icon icon-bell"
                    /></a>
                  </div>
                </div>
                <div
                  class="flight__update-options"
                  v-if="settings.enableFlightUpdates"
                >
                  <label>Get flight updates</label>
                  <a
                    class="icon-wrapper"
                    href="#"
                    @click.prevent="
                      $emit('modal-open', 'WatchFlightModal', {
                        modalHeaderText: modalHeaderText(flight),
                        modalLabelText: settings.emailModalTitle,
                        emailModalOptin: settings.emailModalOptin,
                        emailModalSuccess: settings.emailModalSuccess,
                        flightNumber: flight.flightNumber,
                        flightDateTime: flight.scheduledDateTime,
                      })
                    "
                    ><SvgIcon name="email" class="icon"
                  /></a>
                  <a
                    class="icon-wrapper"
                    href="#"
                    @click.prevent="expandMobileUpdates(null)"
                  >
                    <SvgIcon name="close" class="icon icon-close-flight"
                  /></a>
                </div>
              </div>
              <!--Desktop-->
              <!--Timeline link-->
              <a
                v-if="timelinePage != null"
                :href="
                  getTimePanelLink(
                    timelinePage.url,
                    flight.flightNumber,
                    flight.scheduledDateTime,
                    flight.arriving ? 0 : 1
                  )
                "
                :target="_blank"
              >
                <div
                  :title="`View ${flight.flightNumber}`"
                  class="flight-desktop flight__row"
                >
                  <div class="flight__time">
                    {{ formatDateTime(flight.scheduledDateTime, "time") }}
                  </div>
                  <div class="flight__airline">
                    <img
                      :src="flight.airlineLogo"
                      :alt="flight.airlineName"
                      data-no-retina
                    />
                  </div>
                  <div class="flight__number">
                    <span v-if="!flight.codeShares">
                      {{ flight.flightNumber }}
                    </span>
                    <div class="codeshare" v-if="flight.codeShares">
                      <div class="codeshare__flight-no dynamic">
                        <swiper
                          :direction="'vertical'"
                          :autoplay="{
                            delay: 2000,
                          }"
                        >
                          <swiper-slide>{{ flight.flightNumber }}</swiper-slide>
                          <swiper-slide>
                            <div>
                              {{ flight.codeShares[0].airline + " " }}
                              {{ flight.codeShares[0].flightNumber }}
                            </div></swiper-slide
                          >
                        </swiper>
                      </div>
                      <div class="codeshare__number">
                        <span>+{{ flight.codeShares.length }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flight__destination">
                    {{ flight.location }}
                  </div>
                  <div
                    class="flight__status"
                    :class="flight.statusMessage.colourClass"
                  >
                    {{ flight.statusMessage.mainMessage }}
                  </div>
                  <div
                    class="flight__options"
                    v-if="settings.enableFlightUpdates"
                  >
                    <a
                      class="icon-wrapper"
                      href="#"
                      @click.prevent="
                        $emit('modal-open', 'WatchFlightModal', {
                          modalHeaderText: modalHeaderText(flight),
                          modalLabelText: settings.emailModalTitle,
                          emailModalOptin: settings.emailModalOptin,
                          emailModalSuccess: settings.emailModalSuccess,
                          flightNumber: flight.flightNumber,
                          flightDateTime: flight.scheduledDateTime,
                        })
                      "
                      ><SvgIcon name="email" class="icon"
                    /></a>
                    <!-- <a href="#" class="icon-wrapper"
                    ><SvgIcon name="facebook" class="icon"
                  /></a>
                  <a href="#" class="icon-wrapper"
                    ><SvgIcon name="twitter" class="icon"
                  /></a> -->
                  </div>
                  <!-- TODO
                  <div class="flight__add" ng-if="config.ssoEnabled">
                      <a href="#" ng-click="addFlight(flight)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                 -->
                </div>
              </a>
              <!-- No timeline link-->
              <div
                v-if="timelinePage == null"
                :title="`View ${flight.flightNumber}`"
                class="flight-desktop flight__row"
              >
                <div class="flight__time">
                  {{
                    flight.dateAndTime !== null
                      ? flight.dateAndTime
                      : formatDateTime(flight.scheduledDateTime, "time")
                  }}
                </div>
                <div class="flight__airline">
                  <img
                    :src="flight.airlineLogo"
                    :alt="flight.airlineName"
                    data-no-retina
                  />
                </div>
                <div class="flight__number">
                  <span v-if="!flight.codeShares">
                    {{ flight.flightNumber }}
                  </span>
                  <div class="codeshare" v-if="flight.codeShares">
                    <div class="codeshare__flight-no dynamic">
                      <swiper
                        :direction="'vertical'"
                        :autoplay="{
                          delay: 2000,
                        }"
                      >
                        <swiper-slide>{{ flight.flightNumber }}</swiper-slide>
                        <swiper-slide>
                          <div>
                            {{ flight.codeShares[0].airline + " " }}
                            {{ flight.codeShares[0].flightNumber }}
                          </div></swiper-slide
                        >
                      </swiper>
                    </div>
                    <div class="codeshare__number">
                      <span>+{{ flight.codeShares.length }}</span>
                    </div>
                  </div>
                </div>
                <div class="flight__destination">
                  {{ flight.location }}
                </div>
                <div
                  class="flight__status"
                  :class="flight.statusMessage.colourClass"
                >
                  {{ flight.statusMessage.mainMessage }}
                </div>
                <div
                  class="flight__options"
                  v-if="settings.enableFlightUpdates"
                >
                  <a
                    class="icon-wrapper"
                    href="#"
                    @click.prevent="
                      $emit('modal-open', 'WatchFlightModal', {
                        modalHeaderText: modalHeaderText(flight),
                        modalLabelText: settings.emailModalTitle,
                        emailModalOptin: settings.emailModalOptin,
                        emailModalSuccess: settings.emailModalSuccess,
                        flightNumber: flight.flightNumber,
                        flightDateTime: flight.scheduledDateTime,
                      })
                    "
                    ><SvgIcon name="email" class="icon"
                  /></a>
                  <!-- <a href="#" class="icon-wrapper"
                    ><SvgIcon name="facebook" class="icon"
                  /></a>
                  <a href="#" class="icon-wrapper"
                    ><SvgIcon name="twitter" class="icon"
                  /></a> -->
                </div>
                <!-- TODO
                  <div class="flight__add" ng-if="config.ssoEnabled">
                      <a href="#" ng-click="addFlight(flight)"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                 -->
              </div>
            </div>
            <div class="live-flights__pagination">
              <div class="pagination pagination--dynamic">
                <ul>
                  <li class="pagination__prev">
                    <a
                      href=""
                      class="btn btn--secondary"
                      @click.prevent="prevPage"
                      :class="{
                        disabled: currentPage === 0,
                      }"
                      v-if="this.filteredFlights.length !== 0"
                      >Prev</a
                    >
                  </li>
                  <li v-for="page in pages" :key="page">
                    <a
                      href=""
                      :class="{ pagination__current: currentPage === page }"
                      @click.prevent="goToPage(page)"
                    >
                      {{ page + 1 }}
                    </a>
                  </li>
                  <li class="pagination__next">
                    <a
                      href=""
                      class="btn btn--primary"
                      @click.prevent="nextPage"
                      :class="{
                        disabled: currentPage === paginatedFlights.length - 1,
                      }"
                      v-if="this.filteredFlights.length !== 0"
                      >Next</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import SvgIcon from "@/components/Icon";

SwiperCore.use([Autoplay]);

export default {
  name: "LiveFlightList",
  props: ["componentData"],
  inject: ["publicSiteSettings", "labels"],
  emits: ["modal-open"],
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon,
  },
  data() {
    return {
      searchTerm:
        this.$route.query.search == null ? "" : this.$route.query.search,
      flights: {
        departures: [],
        arrivals: [],
      },
      pageSize: this.componentData.fields.pageSize || 5,
      timelinePage: this.componentData.fields.timelinePage || null,
      currentPage: 0,
      guiState: {
        activeFlightType: "departures",
      },
      displayFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "MM/DD/YYYY"
          : "DD/MM/YYYY",
      timeFormat:
        this.publicSiteSettings.value.fields.globalLocale === "en-US"
          ? "h:mm a"
          : "H:mm",
      expandMobile: null,
      settings: this.publicSiteSettings.value.fields,
    };
  },
  methods: {
    getTimePanelLink(timepanelUrl, flightnumber, flightTime, arriving) {
      let timePageUrl =
        timepanelUrl +
        "?flightCode=" +
        flightnumber +
        "&flightDateTime=" +
        flightTime +
        "&flightDirection=" +
        arriving;
      return timePageUrl;
    },
    switchTab(selectedTab) {
      this.guiState.activeFlightType = selectedTab;
      this.calculateCurrentPageBasedOnTime();
    },
    calculateCurrentPageBasedOnTime() {
      if (this.flights.departures == null && this.flights.arrivals == null)
      {
        return;
      }
      if (!this.flights.departures.length && !this.flights.arrivals.length) {
        return;
      }

      const now = dayjs();
      const flightsList =
        this.guiState.activeFlightType === "arrivals"
          ? this.flights.arrivals
          : this.flights.departures;
      // Find index of the first flight after the current time
      const firstFutureFlightIndex = flightsList.findIndex((flight) => {
        return dayjs(flight.scheduledDateTime).isAfter(now);
      });

      if (firstFutureFlightIndex !== -1) {
        // Calculate page number to display
        this.currentPage = Math.floor(firstFutureFlightIndex / this.pageSize);
      } else {
        // If no future flights found, show the first page
        this.currentPage = 0;
      }
    },
    formatDateTime(incomingDate, returnType) {
      let returnFormat = this.displayFormat + " " + this.timeFormat;
      switch (returnType) {
        case "date":
          returnFormat = this.displayFormat;
          break;
        case "time":
          returnFormat = this.timeFormat;
          break;
      }
      return dayjs(incomingDate).format(returnFormat);
    },
    expandMobileUpdates(index) {
      this.expandMobile = index;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.paginatedFlights.length - 1) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    modalHeaderText: function (flight) {
      return `Watch flight ${flight.flightNumber} ${
        this.guiState.activeFlightType == "arrivals" ? "from" : "to"
      } ${flight.location}`;
    },
  },
  computed: {
    paginatedFlights: function () {
      return this.filteredFlights.reduce((pages, item, index) => {
        const page = Math.floor(index / this.pageSize);
        if (!pages[page]) {
          pages[page] = [];
        }
        pages[page].push(item);
        return pages;
      }, []);
    },
    pages: function () {
      const pageNumbers = [];
      for (let i = 0; i <= this.paginatedFlights.length - 1; i++) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    },
    filteredFlights: function () {
      const searchTermLCase = this.searchTerm.toLowerCase();

      const flightData =
        this.guiState.activeFlightType == "arrivals"
          ? this.flights.arrivals
          : this.flights.departures;

      const filteredData =
        this.searchTerm.length < 2
          ? flightData
          : flightData.filter(({ location, flightNumber, airlineName }) => {
              const clause1 = location ? location : "";
              const clause2 = flightNumber ? flightNumber : "";
              const clause3 = airlineName ? airlineName : "";
              return (
                clause1.toLowerCase().includes(searchTermLCase) ||
                clause2.toLowerCase().includes(searchTermLCase) ||
                clause3.toLowerCase().includes(searchTermLCase)
              );
            });
      return filteredData;
    },
    noResultsText: function () {
      return this.searchTerm.length < 2
        ? `There are currently no ${this.guiState.activeFlightType} to display`
        : `No ${this.guiState.activeFlightType} match your search`;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_REMOTEBASEURL +
          "/api/liveflightsapi/retrieveliveflights?iataCode=" +
          process.env.VUE_APP_IATACODE
      )
      .then((response) => {
        this.flights = response.data;
        this.calculateCurrentPageBasedOnTime();
        console.log(response.data.lastUpdate);
      });
  },
};
</script>

<style lang="scss">
.live-flight-list {
  text-align: center;
  margin: 0 auto;
  padding: 0;

  &__wrapper {
    max-width: 192rem;
    margin: 0 auto;
  }
}

.live-flights {
  &__btns {
    display: inline-block;
    border: 1px solid #dedede;
    border-radius: 2px;
    width: 100%;
    max-width: 44rem;

    @include breakpoint($tablet-sml) {
      max-width: 36rem;
    }

    @include breakpoint($tablet) {
      float: left;
    }

    li {
      width: 50%;
      float: left;

      a {
        color: $live-flights-btns-color;
        border-radius: 0;
        background: $white;
        display: block;
        font-weight: 600;

        &:hover,
        &:focus {
          background: $live-flights-btns-active-bg;
          color: $live-flights-btns-active-color;
        }

        &.js-active {
          background: $live-flights-btns-active-bg;
          color: $live-flights-btns-active-color;
        }
      }

      &.js-active a {
        background: $live-flights-btns-active-bg;
        color: $live-flights-btns-active-color;
      }
    }
  }

  &__header {
    display: none;

    @include breakpoint($tablet) {
      width: 100%;
      display: table;
      text-align: left;
      height: 6.5rem;
      background: $live-flights-header-bg;
      color: $live-flights-header-color;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 3rem;

      span {
        display: table-cell;
        vertical-align: middle;
        line-height: 1.4;
        font-size: 1.45rem;

        @include breakpoint($desktop-sml) {
          font-size: 1.65rem;
        }
      }

      .time {
        padding-left: 2rem;
        width: 10%;
      }

      .number {
        width: 19%;

        @include breakpoint($tablet) {
          width: 14%;
        }
      }

      .destination {
        width: 21%;
      }

      .airline {
        width: 24%;

        @include breakpoint($tablet) {
          width: 18%;
        }
      }

      .status {
        width: 21%;
      }

      .options {
        width: 16%;
        white-space: nowrap;
        text-align: right;
        padding-right: 2rem;
      }

      .add {
        width: 7%;
        text-align: right;
      }
    }
  }

  &__tab {
    display: none;

    &.js-active {
      display: block;
    }
  }

  // mobile panel
  .flight--mobile {
    display: block;

    .live-flights__btns .btn {
      padding: 1.5rem;
      min-width: 12rem;

      @include breakpoint($palm) {
        min-width: 16rem;
      }
    }

    .live-flights__tab {
      margin-top: 2rem;
    }

    @include breakpoint($tablet-sml) {
      display: none;
    }
  }
}

.flight {
  clear: both;
  position: relative;
  border-bottom: 1px solid transparent;
  border-top: 1px solid $keyline;
  cursor: pointer;
  color: $base-font-color;
  margin-top: 2rem;

  @include breakpoint($tablet) {
    margin-top: 0;
  }

  &__row {
    width: 100%;
    display: flex;
    text-align: left;
    height: 6.5rem;
    align-items: center;

    .flight-mobile & {
      padding: 1.5rem 0;
    }

    @include breakpoint($desktop-sml) {
      &:hover,
      &:focus {
        background: $flight-bg-hover;
      }
    }

    .flight__destination,
    .flight__time,
    .flight__number,
    .flight__options,
    .flight__status {
      align-items: center;
      display: flex;
    }

    div {
      font-size: 1.6rem;
      line-height: 1.4;

      @include breakpoint($tablet) {
        font-size: 1.45rem;
      }

      @include breakpoint($desktop-sml) {
        font-size: 1.65rem;
      }
    }
  }

  &__time {
    padding-left: 2rem;
    width: 10%;

    .flight-mobile & {
      width: 20%;
    }
  }

  &__number {
    width: 19%;

    display: table-cell;
    vertical-align: middle;

    @include breakpoint($tablet) {
      width: 14%;
    }

    .flight-mobile & {
      width: 25%;
      text-align: right;
      justify-content: flex-end;
    }
  }

  &__destination {
    width: 19%;

    @include breakpoint($tablet) {
      width: 21%;
    }

    .flight-mobile & {
      width: 55%;
      font-size: 2rem;
    }
  }

  &__status {
    width: 22%;
    padding-right: 1rem;

    @include breakpoint($tablet) {
      width: 21%;
    }

    &.warning {
      color: $alert-warning; // red
    }

    &.success {
      color: $alert-success; // green
    }

    &.attention {
      color: $alert-attention; // yellow
    }

    &.notification {
      color: $flight-notification; // blue
    }

    .flight-mobile & {
      width: 95%;
      padding-right: 0;
      font-size: 1.6rem !important;
      text-transform: uppercase;
    }
  }

  &__airline {
    width: 24%;

    img {
      max-width: 10rem;
      max-height: 6rem;
    }

    @include breakpoint($tablet) {
      width: 18%;
    }

    .flight-mobile & {
      width: 56%;
    }
  }

  &__options {
    position: relative;
    z-index: 2;
    width: 6%;
    justify-content: center; //space-between - when more than 1 icon
    padding: 0 2rem;

    @include breakpoint($tablet) {
      width: 16%;
    }

    .flight-mobile & {
      width: 4%;
    }
  }

  &__update-options {
    width: 100%;
    text-align: center;
    display: none;
    padding: 0 0 10px;
    user-select: none;

    a {
      display: inline-block;
      vertical-align: middle;

      &:last-child {
        float: right;

        .icon {
          margin: 0.25rem 0 0;
        }
      }

      &.add-flight-btn {
        position: relative;
        width: 3rem;
        height: 3rem;
        border: 1px solid $keyline;
        display: inline-block;
        vertical-align: middle;

        i {
          font-size: 1.2rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $icon-color;
        }
      }
    }
  }

  .flight-mobile.expanded .flight__update-options {
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 22px;
    right: 2rem;
    background: #fff;
    padding: 0;
    text-align: left;

    .icon {
      margin-right: 2rem;
    }

    label {
      text-transform: none;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0;
      color: $flight-update-options-color;
      font-family: $font-base;
    }
  }

  .flight-mobile & {
    width: 100%;
    text-align: center;
    display: none;
    padding: 0 0 10px;

    @include breakpoint($tablet-sml-only) {
      height: 20px;
    }
  }

  &-mobile {
    @include breakpoint($tablet) {
      display: none;
    }

    &.expanded .flight__row {
      opacity: 0.2;
    }

    .flight__link {
      display: block;
      height: auto;
      width: 100%;
    }

    .flight__row {
      display: table;
      text-align: left;

      & div {
        padding: 0.5rem 0;
        float: left;
      }
    }
  }

  &-desktop {
    display: none;

    @include breakpoint($tablet) {
      display: flex;
    }
  }

  .codeshare {
    position: relative;
    max-width: 90px;
    padding: 0 !important;

    &__flight-no {
      display: inline-block;
    }

    .codeshare__flight-no.dynamic {
      display: flex;
      height: 6.5rem;

      .swiper-slide {
        height: 6.5rem;
        display: flex;
        align-items: center;
      }
    }

    &__number {
      position: absolute;
      left: 70px;
      top: 50%;
      transform: translateY(-50%);
      padding: 5px 0 0 5px !important;
      margin-top: -2px;

      @include breakpoint($tablet) {
        left: 80px;
        margin-top: 0;
      }

      span {
        width: 20px;
        height: 20px;
        font-size: 1.1rem;
        font-weight: $bold;
        padding: 0.25rem;
        background: $keyline;
        border-radius: 2px;
        display: block;
      }
    }

    &__popup {
      display: none;
      position: absolute;
      top: 4rem;
      left: 0;
      z-index: 1;
      padding: 1.5rem !important;
      background: #ffffff;
      border: 1px solid $keyline;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 2px;
    }
  }
}

.flights {
  &__list {
    background: #fff;
    position: relative;
    margin-top: 3rem;
    clear: both;

    @include breakpoint($responsive-nav) {
      margin-top: 0;
      padding: 0 6rem 6rem;
    }
  }
}

// search bar on the live flights page
.live-flights-search {
  position: relative;
  margin: 2rem auto 0;
  max-width: 44rem;

  @include breakpoint($tablet-sml) {
    max-width: 36rem;
  }

  @include breakpoint($tablet) {
    float: right;
    width: 32rem;
    margin: 0 0 3rem;
  }

  @include breakpoint($desktop-sml) {
    width: 36rem;
  }

  &__field {
    padding-right: 3rem;
    height: 52px !important;
    line-height: 52px !important;
  }

  &__wrapper {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pagination {
  text-align: center;
  display: none;
  margin-top: 3rem;

  @include breakpoint($tablet-sml) {
    display: block;
  }

  li {
    display: inline-block;

    a:not(.btn) {
      display: block;
      font-size: 1.8rem;
      color: $live-flights-btns-color;
      margin: 0 0.5rem;
      padding: 1rem;
      cursor: pointer;

      &.pagination__current,
      &:hover,
      &:active {
        color: #213368;
        text-decoration: none;
      }
    }

    &.unclickable a {
      cursor: default;
      color: #00a8e1;

      &.pagination__current,
      &:hover,
      &:active {
        cursor: default;
        color: #00a8e1;
      }
    }
  }

  &--dynamic {
    display: block;

    @media (max-width: 899px) {
      li {
        display: none;

        &.pagination__prev,
        &.pagination__next {
          display: inline-block;

          &.disabled {
            display: none;
          }
        }

        a {
          margin: 0;
        }
      }
    }

    .pagination__prev {
      float: left;
    }

    .pagination__next {
      float: right;
    }

    .disabled {
      visibility: hidden;
    }
  }
}

.no-results-text {
  margin-top: 2rem;
}

.icon-wrapper {
  svg {
    width: 16px;
    max-height: 18px;
    color: $live-flights-btns-color;
  }
}

.icon-search {
  width: 18px;
  height: 18px;
  color: $label;
}

.icon-bell,
.icon-close-flight {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
  color: $skin-colour-primary-bright;

  @include breakpoint($tablet) {
    display: none;
  }
}
</style>
