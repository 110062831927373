<template>
  <div
    class="hero-slider__slide"
    :style="{
      backgroundImage:
        'url(' + heroData.fields.image.fields.umbracoFile.src + ')',
    }"
  >
    <div class="inner center">
      <div class="hero__content">
        <h1 v-if="heroData.fields.title" class="hero__heading">
          {{ heroData.fields.title }}
        </h1>
        <p v-if="heroData.fields.subtitle">{{ heroData.fields.subtitle }}</p>
        <a
          v-if="heroData.fields.link"
          class="btn btn--primary"
          :target="heroData.fields.link.target ? '_blank' : null"
          :href="heroData.fields.link.url"
          >{{ heroData.fields.link.name }}</a
        >
      </div>
    </div>
    <div class="hero__gradient"></div>
  </div>
</template>

<script>
export default {
  name: "HeroPlain",
  props: ["heroData"],
};
</script>
